// PRIMARY
$front-primary: #265564;
$front-primary-dark: #426A78;
$front-primary-dark-2: #4A4A4A;
// GREEN
$front-green: #00A888;
$front-green-light: #00A888;
$front-green-light-2: #D8D8D8;
$front-green-light-secondary: #B2E4DB;
$front-green-extra-light: #E7F8EB;
$front-green-icon: #C2CCD2;
$admin-green: #DFEDCF;
// WHITE
$front-white: #FFFFFF;
// BLACK
$front-black: #000000;
// GRAY
$front-gray: #93AAB2;
$front-light-gray: #ECECEC;
$front-light-gray-2: #F3F6F8;
$front-light-gray-3: #A9A9A9;
// RED
$front-red: #F4324F;
$front-red-light: #F7E3E6;

$primary:   $front-primary;
$success:   $front-green;
$info:      #0dcaf0;
$secondary: #6c757d;
$white: #ffffff;
$warning:   #ffc107;
$danger:    $front-red;
$light:     $front-light-gray;
$dark:      $front-black;

$orange-primary: #EA580B;
$orange-secondary: #FADECE;
$blue-primary: #25C5ED;
$blue-secondary: #CFF4FE;
$green-primary: #00A888;
$green-secondary: #B2E4DB;
$yellow-primary: #B08C08;
$yellow-secondary: #FFE78F;
$red-primary: #F12A2A;
$red-secondary: #F7D0D0;
$pink-primary: #F746FF;
$pink-secondary: #FDD0FF;
$purple-primary: #7252D3;
$purple-secondary: #DCD1FE;

$background-light: #F8FAFC;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success-light":    $front-green-light-secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "white":      $white,
) !default;
